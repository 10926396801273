<script>
import Modal from "./Modal.vue";

export default {
  name: "TreeItem",
  components: { modal: Modal },
  props: {
    model: Object,
  },
  data() {
    return {
      isOpen: false,
      modalAction: "",
    };
  },
  computed: {
    isFolder() {
      return this.model.children && this.model.children.length;
    },
  },
  methods: {
    toggle() {
      if (this.isFolder) {
        this.isOpen = !this.isOpen;
      }
    },

    applyButtonStyleWhenLink(text) {
      if (text.includes('<a')) {
        text = text.replace('<a', '<a class="btn btn-secondary fw-bold" ')
      }

      return text
    }
  },
};
</script>

<template>
  <div>
    <li v-if="model.tag != ''">
      <div :class="[isFolder ? 'btn btn-secondary' : model.tag != 'Content Painel' && !model.tag.includes('Question(s)') ? 'border border-secondary p-2 rounded' : '', model.tag == 'Content Painel' ? 'btn btn-secondary' : '', isOpen ? 'mb-2' : 'mb-1']" type="button" @click="toggle">
        <span :class="[isFolder ? 'bold-items' : '', model.tag == 'Content Painel' ? 'bold-items' : '']" v-html="applyButtonStyleWhenLink(model.tag)"></span>
        &nbsp;<i v-if="isFolder" :class="['bi', isOpen ? 'bi-arrows-angle-contract' : 'bi-arrows-angle-expand',]"></i>&nbsp;
      </div>

      <ul v-show="isOpen" v-if="isFolder" class="list-items">
        <TreeItem
          class="item"
          v-for="model in model.children"
          :model="model"
          v-bind:key="model"
        >
        </TreeItem>
      </ul>

      <!-- <div>
        <span :class="{ 'bold-items': isFolder }" v-html="model.tag"></span>
        &nbsp;
        <i v-if="isFolder" @click="toggle" :class="['bi', isOpen ? 'bi-arrows-angle-contract' : 'bi-arrows-angle-expand',]"></i>
      </div>

      <ul v-show="isOpen" v-if="isFolder" class="list-items">
        <TreeItem
          class="item"
          v-for="model in model.children"
          :model="model"
          v-bind:key="model"
        >
        </TreeItem>
      </ul> -->
    </li>
  </div>
</template>

<style scoped>
.list-items {
  list-style-type: none;
  margin-left: 10px;
}
.bold-items {
  font-weight: bold;
}
</style>