<script>
import { http } from "../../http";
import MessageCard from "../shared/message-cards/MessageCard.vue";
import Loader from "../shared/painel/Loader.vue";

export default {
  components: {
    "message-card": MessageCard,
    Loader,
  },
  data() {
    return {
      loading: true,
      allMessages: null,
      startDate: this.getToday(),
      endDate: this.getToday(),
      selectedRange: this.getTodayRange(),
      count_messages: {
        total_messages: 0,
        total_messages_delivered: 0,
        total_messages_failed: 0,
        total_messages_queued: 0,
      },
    };
  },
  mounted() {
    this.getAllMessages();
  },
  methods: {
    getAllMessages: async function () {
      var payload = {
        start_date: this.startDate,
        end_date: this.endDate,
      };

      await http.post("painel/atend_comercial/report_by_messaging_status/", payload)
      .then((response) => (this.allMessages = response.data))
      .finally(() => {
        this.calculateTotalMessages();
        setTimeout(() => {
          this.loading = false
        }, 1000);
      });
    },

    calculateTotalMessages() {
      this.count_messages.total_messages = 0;
      this.count_messages.total_messages_delivered = 0;
      this.count_messages.total_messages_failed = 0;
      this.count_messages.total_messages_queued = 0;

      this.allMessages.forEach((message) => {
        this.count_messages.total_messages += message.total_messages;
        this.count_messages.total_messages_delivered +=
          message.total_messages_delivered;
        this.count_messages.total_messages_failed +=
          message.total_messages_failed;
        this.count_messages.total_messages_queued +=
          message.total_messages_queued;
      });
    },

    applyFilters() {
      this.allMessages = null;
      this.count_messages = {
        total_messages: 0,
        total_messages_delivered: 0,
        total_messages_failed: 0,
        total_messages_queued: 0,
      };

      if (this.selectedRange) {
        this.startDate = `${this.selectedRange[0].getDate()}/${
          this.selectedRange[0].getMonth() + 1
        }/${this.selectedRange[0].getFullYear()}`;

        if (this.selectedRange[1]) {
          this.endDate = `${this.selectedRange[1].getDate()}/${
            this.selectedRange[1].getMonth() + 1
          }/${this.selectedRange[1].getFullYear()}`;
        } else {
          this.endDate = this.startDate;
        }
      } else {
        this.startDate = null;
        this.endDate = null;
      }

      this.getAllMessages();
    },

    getToday() {
      const today = new Date();

      return `${today.getDate()}/${
        today.getMonth() + 1
      }/${today.getFullYear()}`;
    },

    getTodayRange() {
      const today = new Date();

      return [today, today];
    },
  },
};
</script>

<template>
  <div class="container-fluid">
    <h1 class="h3 mb-2 text-gray-800">Informações das Mensagens</h1>

    <div v-if="loading === true" class="d-flex justify-content-center">
        <Loader></Loader>
    </div>

    <div v-if="loading !== true" class="row">
      <message-card
        :nameCard="'Total de Mensagens'"
        :amount="count_messages.total_messages"
        :color="'info'"
      ></message-card>

      <message-card
        :nameCard="'Mensagens Entregues'"
        :amount="count_messages.total_messages_delivered"
        :color="'success'"
      ></message-card>

      <message-card
        :nameCard="'Mensagens na Fila'"
        :amount="count_messages.total_messages_queued"
        :color="'warning'"
      ></message-card>

      <message-card
        :nameCard="'Mensagens com Falha'"
        :amount="count_messages.total_messages_failed"
        :color="'danger'"
      ></message-card>
    </div>

    <div v-if="loading !== true" class="card shadow mb-4 mt-5">
      <div class="card-header py-3">
        <div class="d-flex align-items-center justify-content-end">
          <div class="col-3 d-inline-block">
            <VueDatePicker
              v-model="selectedRange"
              range
              :enable-time-picker="false"
              format="dd/MM/yyyy"
              select-text="Selecionar"
              cancel-text="Cancelar"
            />
          </div>

          <button
            type="button"
            class="btn btn-outline-primary ml-2"
            title="Filtrar"
            @click="applyFilters"
          >
            Aplicar Filtro
          </button>
        </div>
      </div>

      <div class="card-body">
        <div class="table-responsive">
          <table
            class="table table-bordered"
            id="dataTable"
            width="100%"
            cellspacing="0"
            selectedClass="table-info"
          >
            <thead>
              <tr>
                <th class="align-middle">Nome do Cliente</th>
                <th class="align-middle text-center">Tipo do Curso</th>
                <th class="align-middle">ID do Curso</th>
                <th class="align-middle">Nome do Curso</th>
                <th class="align-middle text-center">Total de Enrollments</th>
                <th class="align-middle text-center">Enviadas</th>
                <th class="align-middle text-center">Entregues</th>
                <th class="align-middle text-center">Lidas</th>
                <th class="align-middle text-center">Na Fila</th>
                <th class="align-middle text-center">Com Falha</th>
                <th class="align-middle text-center">Reenviadas</th>
                <th class="align-middle text-center">TOTAL</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="data in allMessages" v-bind:key="data.id">
                <td class="align-middle">{{ data.enrollment__course__company__client__name }}</td>
                <td class="align-middle text-center">
                  {{
                    data.enrollment__course__flow_execution == "original"
                      ? "Alya"
                      : data.enrollment__course__flow_execution == "chatbots"
                      ? "Lumy"
                      : data.enrollment__course__flow_execution
                  }}
                </td>
                <td class="align-middle">{{ data.enrollment__course__id }}</td>
                <td class="align-middle">{{ data.enrollment__course__name }}</td>
                <td class="align-middle text-center">{{ data.total_enrollments }}</td>
                <td class="align-middle text-center">{{ data.total_messages_sent }}</td>
                <td class="align-middle text-center">{{ data.total_messages_delivered }}</td>
                <td class="align-middle text-center">{{ data.total_messages_read }}</td>
                <td class="align-middle text-center">{{ data.total_messages_queued }}</td>
                <td class="align-middle text-center">{{ data.total_messages_failed }}</td>
                <td class="align-middle text-center">{{ data.total_messages_resent }}</td>
                <td class="align-middle text-center">{{ data.total_messages }}</td>
              </tr>
            </tbody>
          </table>
          <div v-if="!this.allMessages" class="container-loading">
            <i class="bi bi-hourglass-split">Carregando...</i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.container-loading {
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-header {
  margin: 0 10px;
}
</style>
