<template>
  <div class="container-fluid">
    <div style="margin-bottom: 15px">
      <a :href="'/?#/course/' + this.course_id">
        <button class="btn btn-outline-secondary">
          <i class="bi bi-arrow-left-circle"></i> Voltar
        </button>
      </a>
    </div>
    <div>
      <h1>Questions da Lesson</h1>
      
      <div class="card shadow mt-5 p-3">
        <div class="card-body">
          <div class="row no-gutters align-items-center">
            <div class="col-12">
              <div class="text-sm font-weight-bold text-info text-uppercase mb-1">
                Questions
              </div>

              <div v-if="this.loader == false" class="accordion" id="questionsAccordion">
                <div v-if="this.allQuestions != null && this.allQuestions != ''" class="accordion-item" v-for="(question, index) in this.allQuestions" :key="question.order_id">
                  <h2 class="accordion-header">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      :data-bs-target="`#collapse${index}`"
                      aria-expanded="false"
                      :aria-controls="`collapse${index}`"
                    >
                      {{ `Question ${question.order_id} - ${question.type_question}` }}
                    </button>
                  </h2>

                  <div
                    :id="`collapse${index}`"
                    class="accordion-collapse collapse"
                    :aria-labelledby="`heading${index}`"
                    :data-bs-parent="`#questionsAccordion${index}`"
                  >
                    <div class="accordion-body">
                      <p>
                        <strong>Statement:</strong>
                        &nbsp;{{ question.statement }}
                      </p>

                      <p>
                        <strong>Alternatives:</strong><br>
                        <ul>
                          <li v-for="alternative in question.alternatives" :key="alternative.order_id">
                            {{ alternative.content_text }}
                            &nbsp;&nbsp;&nbsp;<button type="button" class="btn btn-outline-info btn-sm rounded-pill" data-bs-toggle="modal" :data-bs-target="`#feedBackModalQ${question.order_id}A${alternative.order_id}`">Feedback</button>

                            <!-- Modal -->
                            <div class="modal fade" :id="`feedBackModalQ${question.order_id}A${alternative.order_id}`" tabindex="-1" :aria-labelledby="`#feedBackModalLabelQ${question.order_id}A${alternative.order_id}`" aria-hidden="true">
                              <div class="modal-dialog">
                                <div class="modal-content">
                                  <div class="modal-header">
                                    <h1 class="modal-title fs-5" :id="`feedBackModalLabelQ${question.order_id}A${alternative.order_id}`">Feedback</h1>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                  </div>

                                  <div class="modal-body">
                                    <span v-html="alternative.content_feedback"></span>
                                    
                                    <div v-if="alternative.content_feedback.includes('<')"><br><b>HTML Injection:</b><br>{{ alternative.content_feedback }}</div>
                                  </div>

                                  <div class="modal-footer">
                                    <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </p>                      
                    </div>
                  </div>
                </div>
                <div v-else>
                  <br>
                  <p v-if="this.apiError">Erro ao carregar questões.</p>
                  <p v-else>Nenhuma questão cadastrada.</p>
                </div>
              </div>
              <div v-else style="display: flex; flex: 1; justify-content: center;">
                <Loader />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { http } from "../../http";
import Loader from "../shared/painel/Loader.vue";

export default {
  name: "LessonDetail",

  components: {
    Loader,
  },
  
  data() {
    return {
      loader: true,
      course_id: this.$route.params.course,
      lesson_id: this.$route.params.id,
      endpoint: "get-all-questions/",
      allQuestions: null,
      apiError: false,
    };
  },  

  mounted() {
    this.getAllQuestions()
  },

  methods: {    
    getAllQuestions() {
      let payload = {
        lesson_id: this.lesson_id,
      }

      http.post(this.endpoint, payload)
      .then((response) => {
        this.allQuestions = response.data.questions

        setTimeout(() => {
          this.loader = false            
        }, 1000)
      })
      .catch((error) => {
        console.error(error)
        this.apiError = true     
      });
    }
  },
};
</script>

<style>
.container-fluid {
  padding: 20px;
}

h1 {
  margin-bottom: 20px;
}

.card {
  margin-bottom: 20px;
}

.card-body {
  padding: 15px;
}

.text-xs {
  font-size: 0.75rem;
}

.font-weight-bold {
  font-weight: 700;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-info {
  color: #17a2b8 !important;
}

.text-gray-300 {
  color: #d1d3e2 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.shadow {
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15) !important;
}

.h-100 {
  height: 100% !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  border: 1px solid #ddd;
  padding: 8px;
  min-width: 100px; 
}

th {
  background-color: #f2f2f2;
  text-align: left;
}
</style>