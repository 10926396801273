<script>
import TreeItemLesson from "./TreeItemLesson.vue";
import { http } from "../../http";

const treeData = {
  tag: "Carregando dados...",
  is_main: true,
  children: [],
};

export default {
  components: {
    TreeItem: TreeItemLesson,
  },
  props: {
    treeData: {
      type: Array,
      required: true,
    },
  },
};
</script>

<template>
    <ul class="spacing-axiosY">
      <TreeItem class="item spacing-axiosY card border-left-secondary p-2" :model="treeData"></TreeItem>
    </ul>
</template>

<style scoped>
.item {
  line-height: 1.5;
}
.spacing-axiosY {
  margin: 0;
  display: flex;
}
ul {
  padding-left: 0;
  list-style-type: none;
}
</style>