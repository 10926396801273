import { createApp } from 'vue'
import App from '../App.vue'
import axios from 'axios';
import { useCookies } from "vue3-cookies"


const modelCookies = useCookies()
var token = modelCookies.cookies.get("user_session")

if (token == null){
    var headers = {
        'Content-Type': 'application/json',
        'X-CSRFToken': "d0668c56d2b7bb95c9f2d9b76d4e87f83341c9bd",
        
    } 
}else if (token == ""){
    var headers = {
        'Content-Type': 'application/json',
        'X-CSRFToken': "d0668c56d2b7bb95c9f2d9b76d4e87f83341c9bd",
       
    } 
}else {
var headers = {
    'Content-Type': 'application/json',
    'Authorization': "Token " + token,
    'X-CSRFToken': token
    }
}

var headers_file = {
    'Content-Type':  'multipart/form-data',
    'Authorization': "Token " + token,
    'X-CSRFToken': token
}

const http = axios.create({
    // baseURL: 'https://ie-chat-bot-lab-multiplossender.lys.academy/api/v1',
    // baseURL: 'https://api-cron.lys.academy/api/v1',
    baseURL: 'https://d8aa-138-185-96-181.ngrok-free.app/api/v1',
    //timeout: 1000,
    headers: headers
  });

  const http_file = axios.create({
    // baseURL: 'https://ie-chat-bot-lab-multiplossender.lys.academy/api/v1',
    // baseURL: 'https://api-cron.lys.academy/api/v1',
    baseURL: 'https://internal-api.lys.academy/api/v1',
    // baseURL: 'https://fa8c-167-249-188-116.ngrok-free.app/api/v1',
    //timeout: 1000,

    headers: headers_file
});



export { http, http_file }  