<script>
import { http } from "../../http";
import Loader from "../shared/painel/Loader.vue";

export default {
  components: {
    Loader,
  },

  data() {
    return {
      loading: true,
      allEnrollments: null,
      selectedRange: null,
      startDate: null,
      endDate: null,
      filterType: null,
      filterStatus: null,
    };
  },
  mounted() {
    this.getAllEnrollments();
  },
  methods: {
    getAllEnrollments: async function () {
      var payload = {
        course_status: this.filterStatus,
        course_type: this.filterType,
        start_date: this.startDate,
        end_date: this.endDate,
      };

      await http.post("enrollments-information/", payload)
      .then((response) => (this.allEnrollments = response.data))
      .finally(() => {
        setTimeout(() => {
          this.loading = false
        }, 1000);
      });
    },

    onChangeFilterType() {
      this.filterType = document.getElementById("selectFilterType").value;
    },

    onChangeFilterStatus() {
      this.filterStatus = document.getElementById("selectFilterStatus").value;
    },

    applyFilters() {
      this.allEnrollments = null;

      if (this.selectedRange) {
        this.startDate = `${this.selectedRange[0].getDate()}/${
          this.selectedRange[0].getMonth() + 1
        }/${this.selectedRange[0].getFullYear()}`;

        if (this.selectedRange[1]) {
          this.endDate = `${this.selectedRange[1].getDate()}/${
            this.selectedRange[1].getMonth() + 1
          }/${this.selectedRange[1].getFullYear()}`;
        } else {
          this.endDate = this.startDate;
        }
      } else {
        this.startDate = null;
        this.endDate = null;
      }

      this.getAllEnrollments();
    },
  },
};
</script>

<template>
  <div class="container-fluid">
    <h3 class="text-gray-800 mb-0 pb-0">Informações dos Alunos</h3>

    <div v-if="loading === true" class="d-flex justify-content-center">
        <Loader></Loader>
    </div>

    <div v-else class="card shadow mb-4 mt-4">
      <div class="card-header py-3">        
        <div class="d-flex align-items-center justify-content-end">
          <select
            class="custom-select col-2 btn-header"
            id="selectFilterType"
            @change="onChangeFilterType"
          >
            <option value="" selected>TIPO DO CURSO</option>
            <option value="alya">Alya</option>
            <option value="lumy">Lumy</option>
          </select>

          <select
            class="custom-select col-2 btn-header"
            id="selectFilterStatus"
            @change="onChangeFilterStatus"
          >
            <option value="" selected>STATUS DO CURSO</option>
            <option value="active">Ativo</option>
            <option value="inactive">Inativo</option>
          </select>

          <div class="col-3 d-inline-block">
            <VueDatePicker
              v-model="selectedRange"
              range
              :enable-time-picker="false"
              format="dd/MM/yyyy"
              select-text="Selecionar"
              cancel-text="Cancelar"
            />
          </div>

          <button
            type="button"
            class="btn btn-outline-primary ml-2"
            title="Filtrar"
            @click="applyFilters"
          >
            Aplicar Filtro
          </button>
        </div>
      </div>
      <div class="card-body">
        <div class="table-responsive">
          <table
            class="table table-bordered"
            id="dataTable"
            width="100%"
            cellspacing="0"
            selectedClass="table-info"
          >
            <thead>
              <tr>
                <th class="align-middle">ID</th>
                <th class="align-middle">Nome do Curso</th>
                <th class="text-center align-middle">Tipo do curso</th>
                <th class="text-center align-middle">Curso está Ativo?</th>
                <!-- <th>Projeto</th> -->
                <th class="text-center align-middle">Alunos Ativos</th>
                <th class="text-center align-middle">Alunos Formados</th>
                <th class="text-center align-middle">Total de Inscritos</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="data in allEnrollments" v-bind:key="data.id">
                <td>#{{ data.id }}</td>
                <td>{{ data.name }}</td>
                <td class="text-center">
                  {{
                    data.flow_execution == "original"
                      ? "Alya"
                      : data.flow_execution == "chatbots"
                      ? "Lumy"
                      : data.flow_execution
                  }}
                </td>
                <td class="text-center">
                  <i
                    v-if="data.is_active"
                    class="bi bi-check-circle-fill text-success"
                  ></i>
                  <i v-else class="bi bi-x-circle-fill text-danger"></i>
                </td>
                <!-- <td>{{ data.project_name }}</td> -->
                <td class="text-center">{{ data.active_enrollments }}</td>
                <td class="text-center">{{ data.completed_enrollments }}</td>
                <td class="text-center">{{ data.all_enrollments }}</td>
              </tr>
            </tbody>
          </table>
          <div v-if="!this.allEnrollments" class="container-loading">
            <i class="bi bi-hourglass-split">Carregando...</i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.container-loading {
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-header {
  margin: 0 10px;
}
</style>
