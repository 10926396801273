<template>
  <div class="container-fluid">
    <div style="margin-bottom: 15px">
      <a href="?#/list-info-course">
        <button class="btn btn-outline-secondary">
          <i class="bi bi-arrow-left-circle"></i> Voltar
        </button>
      </a>
    </div>
    <div>
      <h1>Detalhes do Curso</h1>
      <div v-if="course && loader === false">
        <div class="row">
          <div class="col-md-6 col-lg-4 mb-4" v-for="section in sections" :key="section.title">
            <div class="card border-left-info shadow h-100 py-2">
              <div class="card-body">
                <div class="row no-gutters align-items-center">
                  <div class="col-12">
                    <div class="text-xs font-weight-bold text-info text-uppercase mb-1">
                      {{ section.title }}
                    </div>
                    <div v-for="field in section.fields" :key="field.label" class="mb-1">
                      <strong>{{ field.label }}:</strong> {{ formatFieldValue(field.value) }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <InteractionMessages :fields="interactionMessagesFields" />
        <div class="card border-left-info shadow h-100 py-2">
          <div class="card-body">
            <div class="row no-gutters align-items-center">
              <div class="col-12">
                <div class="text-xs font-weight-bold text-info text-uppercase mb-1">
                  Lessons
                </div>
                <tree-lesson :treeData="treeLessons"></tree-lesson>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else style="display: flex; flex: 1; justify-content: center;">
        <Loader />
      </div>
    </div>
  </div>
</template>

<script>
import { http } from "../../http";
import InteractionMessages from "./InteractionMessages.vue";
import TreeLesson from "../command-tree/TreeLesson.vue";
import Loader from "../shared/painel/Loader.vue";

export default {
  name: "CourseDetail",
  components: {
    InteractionMessages,
    "tree-lesson": TreeLesson,
    Loader,
  },
  data() {
    return {
      course: null,
      loader: true,
    };
  },
  computed: {
    sections() {
      if (!this.course) return [];
      return [
        {
          title: "Informações Gerais",
          fields: [
            { label: "Nome", value: this.course.name },
            { label: "Código do Projeto", value: this.course.company },
            { label: "Ativo", value: this.course.is_active ? 'Sim' : 'Não' },
            { label: "Solução", value: this.course.flow_execution === 'original' ? 'Alya' : this.course.flow_execution === 'chatbots' ? 'Lumy' : '' },      
            { label: "Tipo de Chatbot", value: this.course.chatbot_type },
            { label: "Pontuação do Curso", value: this.course.course_score },
            { label: "Descrição", value: this.course.description },
            { label: "Imagem Painel de Cursos", value: this.course.image_url },
          ],
        },
        {
          title: "Configurações de Lição",
          fields: [
            { label: "Token do Curso", value: this.course.external_exercise_id },
            { label: "Dias de Envio da Lição", value: this.formatDays(this.course.lesson_weekdays) },
            { label: "Dias de Envio do Push", value: this.formatDays(this.course.push_weekdays) },
            { label: "Hora de Envio da Lição", value: this.course.lesson_time },
            { label: "Limite de Hora de Envio da Lição", value: this.course.lesson_time_limit },
            { label: "Hora de Envio do Lembrete", value: this.course.remember_lesson_time },
            { label: "Minutos a Menos para Enviar as Aulas", value: this.course.minutes_less },
            { label: "Dias de Espera para a Primeira Lição", value: this.course.first_lesson_wait_days },
            { label: "Feriados", value: this.course.holiday_blacklist },
          ],
        },
        {
          title: "Outras Configurações",
          fields: [
            { label: "Parar Inscrições", value: this.course.stop_subscriptions ? 'Sim' : 'Não' },
            { label: "Poucos Pushes", value: this.course.few_pushes ? 'Sim' : 'Não' },
            { label: "Modelos de Mensagens", value: this.course.messages_templates },
            { label: "Tutores", value: this.course.tutors },
            { label: "Enviar Mensagem de Alerta na Lição", value: this.course.send_alert_message_on_lesson },
            { label: "Enviar Mensagem de Alerta Após N Horas", value: this.course.send_alert_message_after_n_hours },
            { label: "Inativar na Lição", value: this.course.inactivate_on_lesson },
            { label: "Método de Inativação", value: this.course.inactivation_method },
            { label: "Máximo de Lembretes", value: this.course.max_reminders },
            { label: "Máximo de Resgates", value: this.course.max_rescues },
            { label: "Tempo Entre Lembretes", value: this.course.time_between_reminders },
            { label: "Tempo Entre Resgates", value: this.course.time_between_rescues },
            { label: "Tem Maratona?", value: this.course.is_maratona ? 'Sim' : 'Não' },
            { label: "Gerador de Certificado", value: this.course.certificate_generator },
            { label: "É Curso Introdutório?", value: this.course.is_introductory_course ? 'Sim' : 'Não' },
            { label: "ID da Ordem", value: this.course.order_id },
            { label: "Nivelamento", value: this.course.leveling },
            { label: "Perfil de exibição de Curso", value: this.course.profile },
            { label: "Atraso Personalizado do Lembrete (Horas)", value: this.course.custom_reminder_delay_hours },
          ],
        },
      ];
    },
    interactionMessagesFields() {
      if (!this.course) return [];
      return [
        { label: "Mensagem de Confirmação", value: this.course.confirmation_message },
        { label: "Mensagem Não Confirmada", value: this.course.non_confirmed_message },
        { label: "Mensagem de Lembrete", value: this.course.remember_message },
        { label: "Mensagem de Alerta", value: this.course.alert_message },
        { label: "Mensagem de Notificação de Inativação", value: this.course.inactivation_notification_message },
        { label: "Lembrete da Última Lição", value: this.course.last_lesson_reminder },
        { label: "Mensagem de Resgate", value: this.course.rescue_message },
        { label: "Última Mensagem de Resgate", value: this.course.last_rescue_message },
        { label: "Mensagem de Certificado em Criação", value: this.course.certificate_being_created_message },
      ];
    },
    treeLessons() {
      if (!this.course) return [];
      return this.course.lessons;
    },
  },
  mounted() {
    this.fetchCourseDetails();
  },
  methods: {
    fetchCourseDetails: function () {
      var payload = {
        course_id: this.$route.params.id
      };

      http.post("painel/atend_comercial/course_detail/", payload)
      .then((res) => {
        this.course = res.data;

        setTimeout(() => {
          this.loader = false            
        }, 1000);
      });
    },

    formatFieldValue(value) {
      if (Array.isArray(value)) {
        return value.map(day => this.getDayName(day)).join(', ');
      }
      return value || 'Não cadastrado';
    },

    getDayName(day) {
      const days = ['Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado', 'Domingo'];
      return days[day - 1] || 'Desconhecido';
    },

    formatDays(days) {
      if (!days || !Array.isArray(days)) return 'Não cadastrado';
      const dayNames = ['Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado', 'Domingo'];
      return days.map(day => dayNames[day - 1]).join(', ');
    },
  },
};
</script>

<style>
.container-fluid {
  padding: 20px;
}

h1 {
  margin-bottom: 20px;
}

.card {
  margin-bottom: 20px;
}

.card-body {
  padding: 15px;
}

.text-xs {
  font-size: 0.75rem;
}

.font-weight-bold {
  font-weight: 700;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-info {
  color: #17a2b8 !important;
}

.text-gray-300 {
  color: #d1d3e2 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.shadow {
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15) !important;
}

.h-100 {
  height: 100% !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}
</style>